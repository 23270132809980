body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

* {
  outline: none;
}

.m-tree .m-node .collapse {
  padding: 0.5rem 0;
}

.m-tree .m-node .inner {
  cursor: default;
  margin: 0 !important;
}

.toast-container .Toastify__toast {
  background-color: #fff;
  padding-left: 1em;
  color: #555;
}

.toast-container .Toastify__toast.Toastify__toast--success {
  border-top: 6px solid #009688;
}

.toast-container .Toastify__toast.Toastify__toast--info {
  border-top: 6px solid #1565c0;
}

.toast-container .Toastify__toast.Toastify__toast--warning {
  border-top: 6px solid #fdd835;
}

.toast-container .Toastify__toast.Toastify__toast--error {
  border-top: 6px solid #b71c1c;
}
